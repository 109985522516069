<template>
  <div class="sys-res">
    <page-title :title="'资源管理'">配置菜单等资源数据</page-title>
    <el-button
      icon="el-icon-plus"
      type="success"
      size="small"
      @click="addMenu"
      >新增资源</el-button
    >
    <div class="res-table">
      <el-table
        :data="tableData"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        border
        size="mini"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column type='index' label="序号" width="50" align="center">
        </el-table-column>
        <el-table-column prop="menuName" label="菜单名称" min-width="180" header-align="cnter">
        </el-table-column>
        <el-table-column prop="menuSort" label="排序" width="60" align="center">
        </el-table-column>
        <el-table-column prop="menuIcon" label="图标" align="center" width="60">
          <template slot-scope="scope">
            <i :class="scope.row.menuIcon"></i>
          </template>
        </el-table-column>
        <el-table-column prop="menuUrl" label="菜单路由" align="center"></el-table-column>
        <el-table-column prop="menuPath" label="组件地址" align="center"></el-table-column>
        <el-table-column prop="menuType" label="类型" align="center" width="80"></el-table-column>
        <el-table-column prop="menuStatus" label="状态" align="center" width="80"></el-table-column>
        <el-table-column label="操作" header-align="center" width="220">
          <template slot-scope="scope">
            <el-button size="mini" v-if="scope.row.menuType!='btn'" @click="addNextMenu(scope.row)">添加下级</el-button>
            <el-button size="mini" type="primary" @click="editMenu(scope.row)">编辑</el-button>
            <el-button size="mini" type="warning" @click="delMenu(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      title="新增资源菜单"
      :visible.sync="menuAddDialog"
      custom-class="my-dialog-style"
    >
      <el-form
        ref="form"
        :model="form"
        size="small"
        label-width="100px"
        label-position="right"
      >
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="上级菜单：" prop="parentId">
              <el-popover
                placement="bottom-start"
                width="300"
                v-model="popoverShow"
                trigger="click">
                <el-tree :data="tableData" :props="{label:'menuName'}" :expand-on-click-node="false" default-expand-all @node-click="handleNodeClick"></el-tree>
                <el-input slot="reference" v-model="form.parentMenuName" placeholder="请选择上级菜单" clearable @clear="menuClear"></el-input>
              </el-popover>
              <span class="input-tips">不选则默认为一级菜单</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="菜单名称：" prop="menuName">
              <el-input
                v-model="form.menuName"
                placeholder="请输入菜单名称"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="菜单类型：" prop="menuType">
              <el-radio-group v-model="form.menuType">
                <el-radio label="dir">目录</el-radio>
                <el-radio label="page">页面</el-radio>
                <el-radio label="btn">按钮</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="菜单排序：" prop="menuSort">
              <el-input
                v-model="form.menuSort"
                placeholder="请输入用户昵称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="菜单状态：" prop="menuStatus">
              <el-radio-group v-model="form.menuStatus">
                <el-radio label="0">启用</el-radio>
                <el-radio label="1">禁用</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="菜单图标：" prop="menuIcon">
              <el-input
                v-model="form.menuIcon"
                placeholder="请选择菜单图标"
                :disabled="form.menuType=='btn'"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="菜单编码：" prop="code">
              <el-input
                v-model="form.code"
                placeholder="请输入菜单编码"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="路由地址：" prop="menuUrl">
              <el-input
                v-model="form.menuUrl"
                placeholder="请输入路由地址"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="组件路径：" prop="menuPath">
              <el-input
                v-model="form.menuPath"
                placeholder="请输入组件路径"
                :disabled="form.menuType!='page'"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="备注说明：" prop="remark">
              <el-input
                v-model="form.remark"
                type="textarea"
                rows="3"
                placeholder="请输入说明"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="menuAddDialog = false" size="small">取 消</el-button>
        <el-button type="primary" @click="saveMenu" size="small"
          >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "Resource",
  data() {
    return {
      tableData: [],
      menuAddDialog: false,
      popoverShow:false,
      form: {
        id:null,
        parentId:'',
        parentMenuName:'',
        menuName:'',
        menuType:'dir',
        menuSort:1,
        menuStatus:'0',
        menuUrl:'',
        menuPath:'',
        code:'',
        menuIcon:'',
        remark:'',
      },
    };
  },
  created(){
    this.initData();
  },
  methods: {
    /**初始加载页面数据 */
    initData() {
      const that = this;
      that.request.get("sysmenu/list").then(res=>{
          that.tableData = res.data;
      });
    },
    /** 上级菜单节点选择方法*/
    handleNodeClick(data){
      this.form.parentMenuName = data.menuName;
      this.form.parentId = data.id;
      this.popoverShow = false;
    },
    /**上级菜单节点清空 */
    menuClear(){
      this.form.parentMenuName = null;
      this.form.parentId = null;
    },
    /**添加菜单 */
    addMenu(){
      this.form.parentMenuName = null;
      this.form.parentId = null;
      this.menuAddDialog = true;
    },
    /**添加下级菜单 */
    addNextMenu(obj){
      this.form.id = null
      this.form.parentMenuName = obj.menuName;
      this.form.parentId = obj.id;
      this.menuAddDialog = true;
    },
    /**编辑菜单 */
    editMenu(obj){
      this.form = JSON.parse(JSON.stringify(obj));
      this.menuAddDialog = true;
    },
    /**删除菜单 */
    delMenu(id){
      const that = this;
      that.$confirm('此操作将永久删除该菜单及下级菜单, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                that.request.get("sysmenu/delete",{id:id}).then(res=>{
                    if(res.code===200){
                        that.initData();
                    }
                })
            }).catch(() => {
            });
    },
    /**保存菜单数据 */
    saveMenu() {
      const that  =this;
      that.request.post("sysmenu/save",that.form).then(res=>{
          if(res.code===200){
            that.initData();
            that.menuAddDialog = false;
            that.$refs['form'].resetFields();
          }
      });
    },
    /**重置密码 */
    resetPass(obj) {
      console.log(obj);
    },
    edit() {},
    /**用户删除操作 */
    deleteMenu() {},
  },
};
</script>
<style lang="less" scoped>
.sys-res {
  .res-table {
    margin-top: 15px;
  }
  .input-tips{
      font-size: 12px;
      color: #989898;
      margin-top: 7px;
  }
}
</style>